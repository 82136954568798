<header class="main-header dark-bg py-2">
  <div class="container-fluid px-4">
    <div class="row align-items-center">
      <div class="col-md-6 col-12">
        <div class="brand-logo position-relative">
          <a routerlink="/"><img alt=" " class="img-fluid img-loog" src="assets/images/logo.png" /></a>
          <div class="mobile-menu" (click)="toggleField()">
            <i class="fas fa-bars"></i>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 mt-2 mt-md-0">
        <div
          class="nav-rigt w-max-content ms-auto d-flex align-items-center justify-content-md-end justify-content-between">
          <div class="dropdown w-max-content ps-1">
            <button type="button" data-bs-toggle="dropdown" aria-expanded="false" class="dropdown-toggle border-0">
              <i class="far fa-user"></i>
              <span class="ms-2 fs-14">{{ profileDetail && profileDetail.profile && profileDetail.profile.name ?
                profileDetail.profile.name : '' }}</span>
            </button>
            <ul class="dropdown-menu p-0 fs-14 overflow-hidden">
              <li><a class="dropdown-item text-decoration-none py-2" routerLink="/panel/manage-profile"
                  routerLinkActive="active">Manage Profile</a>
              </li>
              <li><a class="dropdown-item text-decoration-none py-2" (click)="logout()"
                  routerLinkActive="active">Logout</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>