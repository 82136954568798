export const API_Routes = {
  AUTH: {
    LOGIN: 'auth/admin-login',
    FORGOT_PWD: 'auth/forgot-password',
    RESET_PWD: 'auth/reset-password',
    OTP_LOGIN: 'auth/login?type=otp',
    ACCOUNT_STATUS: 'agg/users/accounts',
  },
  ROLES: {
    ADD: 'auth/roles',
    LIST: 'auth/roles',
    GET_LAST_POSITION: 'auth/roles/last-sequence',
  },
  PERMISSIONS: {
    GET: 'auth/permissions',
    UPDATE: 'auth/roles/permissions',
  },
  PROFILE: {
    // GET: 'agg/users'
    GET: 'agg/users/self',
    VERIFY: 'auth/email-verify'
  },
  USERS: {
    ADD: 'agg/users',
    LIST: 'agg/users',
    GET_COUNTRY: 'users/countries',
    UPDATE: 'agg/users',
    EXPORT: 'agg/users/export'
  },
  CATEGORY: {
    ADD: 'setting/enum/categories',
    UPDATE: 'setting/enum/categories',
    LIST: 'setting/enum/categories',
  },
  TERMS_PAGES: {
    LIST: 'setting/pages'
  },
  DASHBOARD: {
    LIST: 'agg/dashboard'
  },
  QUESTIONS: {
    LIST: 'users/questions',
    POSITION: 'users/questions/last-sequence'
  },
  THEME: {
    GET: 'auth/setting',
    LIST: 'auth/themes',
  },
  Driver: {
    DETAIL: 'agg/users/get-car',
    DOCUMENT: 'users/profiles/get-doc',
    ADD_DOCUMENT: 'users/profiles/add-doc',
    UPDATE_DOCUMENT: 'users/profiles/update-doc',
    RC_STATUS: 'agg/users/profiles/update-rc-status',
    DOC_STATUS: 'agg/users/profiles/update-doc-status',
    Profile_Status: 'users/profiles/update-profile-status',
    USER_DETAILS_STATUS: 'agg/users/update-role-status',
    DELETE_DOCUMENT: 'users/profiles/delete-doc',
    ADD_BANK_ACCOUNT: 'users/profiles/add-user-bank-details',
    BANK_DETIALS_LIST: 'users/profiles/get_user_bank_details_list',
    DELETE_BANK_DETIALS: 'users/profiles/delete_user_bank_details',
    UPDATE_BANK_DETIALS: 'users/profiles/update_user_bank_details',
    USER_RATING_LIST: 'users/rating-reviews',
  },
  Vehicle: {
    ENUMS: 'setting/enum/vehicles',
    COLORS: 'setting/enum/colours',
    ADD_CAR: 'users/profiles/add-car',
    UPDATE_CAR: 'users/profiles/update-car',
    RC_ADD: 'users/profiles/update-rc',
    DELETE_CAR: 'users/profiles/delete-vehicle',
    LIST: 'setting/enum/vehicles/vehicleList',
    BRANDLIST: 'setting/enum/vehicles/brands',
    TYPELIST: 'setting/enum/vehicles/types',
    MODELLIST: 'setting/enum/vehicles/models',
    VEHICLE_TYPE_LIST: 'setting/enum/vehicles/brandsTypes',
    DELETE_IMAGE: 'users/profiles/remove_document'
  },
  Offers: {
    GET: 'agg/users/rides',
    GETBYID: 'agg/users/rides',
    STATUS: 'users/rides',
    EDIT: 'users/rides/update'
  },
  Mobile: {
    GET_COUNTRIES: 'setting/countries',
    GET_CITIES: 'setting/get_cities_by_country',
    RATE_DISTANCE: 'setting/rate_distances',
    PEAK_HOURS: 'setting/peak_hours',
    LIST: 'setting/mobile_settings',
    ADD: 'setting/mobile_settings'
  },
  Bookings: {
    GET: 'users/bookings',
    EDIT: 'users/bookings/update'
  },
  EmailSettings: {
    GET: 'setting/email_settings',
    PATCH: 'setting/email_settings'
  },
  Refund: {
    GET: 'agg/users/bookings/refund',
    REFUNDSTATUS: 'payment/payments/is_approved'
  },
  Report: {
    GET: 'agg/spamReport',
    STATUS: 'auth/accounts',
    WARNING: 'users/rides/report',
    DELETE_USER: 'agg/users/remove/details'
  },
  ExportReports: {
    GET: 'agg/users/reports',
    PASSENGER_REPORT: 'agg/users/passenger_reports',
    DRIVER_PENDING_AMOUNT: 'agg/users/driver_pending_amount_reports',
    WALLET_HISTORY: 'users/wallet/histories/list',
    DRIVER_COMMISSION: 'agg/users/driver_commission_reports',
    REPORT_LOGS: 'agg/users/report_logs'
  },
  COMMISSION: {
    LIST: 'setting/commission'
  },
  COUPONS: {
    LIST: 'setting/coupon'
  },
  FINANCIALYEAR: {
    LIST: 'agg/users/financial-year',
    ADD: 'payment/financials'
  },
  LINK: {
    DELETE: 'setting/social_links/',
    PATCH: 'setting/social_links/',
    LIST: 'setting/social_links/getAll',
    ADD: 'setting/social_links/add'
  },
  NOTIFICATION: {
    GET: 'users/notification_settings/',
    LIST: 'agg/users/notifications',
    DELETE: 'users/notification/remove'
  }
};
