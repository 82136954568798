<ng-container>
  <app-cms-header></app-cms-header>
  <div class="container-fluid screen-height mt-5">
    <app-terms [content]="data" *ngIf="screenType === 'terms-conditions'"></app-terms>
    <app-privacy [content]="data" *ngIf="screenType === 'privacy-policy'"></app-privacy>
    <app-faq [content]="data" *ngIf="screenType === 'faq'"></app-faq>
    <app-support [content]="data" *ngIf="screenType === 'support-and-contact'"></app-support>
    <app-about-us [content]="data" *ngIf="screenType === 'about-us'"></app-about-us>
    <app-delete-user-data [content]="data" *ngIf="screenType === 'delete-data'"></app-delete-user-data>
  </div>
  <app-footer></app-footer>
</ng-container>
